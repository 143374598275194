import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from '../../assets/LSDA-scaled.jpg';
import {loginService} from "../../data/services/authService";
import {saveToStorage} from "../../utils/utils";
import {USERNAME} from "../../utils/constants";
import {CircularProgress, Snackbar} from "@material-ui/core";
import Alert from "../../components/unit/Alert/Alert";


const Login = ({history}) => {

	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");

	const [snackErr, setSnackErr] = useState(false);
	const [snackMsg, setSnackMsg] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const submitForm = async () => {
		setIsLoading(true)
		loginService(user, password)
			.then(res => {
				setIsLoading(false)
				saveToStorage(USERNAME, user);
				history.push("/")
			})
			.catch(error => {
				setIsLoading(false)
				setSnackMsg(error)
				setSnackErr(true);
			})

	}

	const handleUserChange = (e) => {
		setUser(e.target.value)
	}

	const handlePasswordChange = (e) => {
		setPassword(e.target.value)
	}

	return (
		<>

			<div style={{
				display: "flex",
				flex: 1,
				height: "100vh",
				width: "100vw",
				alignItems: "center",
				justifyContent: "center"
			}}>


				<Paper elevation={3}
					   style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 40}}>

					<img src={logo} style={{height: 46, width: 46, objectFit: "contain", marginTop: -23}}/>
					<span style={{display: "flex", marginTop: 40}}>Accés al panell de gestió</span>

					<form onSubmit={(event) => {
						event.preventDefault();
						submitForm()
					}}>

						<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
							<TextField style={{display: "flex", marginTop: 30, width: 260}}
									   id="user" label="Usuari" variant="outlined" onChange={handleUserChange}/>
							<TextField style={{display: "flex", marginTop: 20, width: 260}} id="password"
									   label="Contrasenya"
									   variant="outlined" type="password" onChange={handlePasswordChange}/>
							<a href="recuperar-contrasenya" style={{display: "flex", marginTop: 20}}> Has oblidat la
								contrasenya? </a>

							<Button style={{display: "flex", marginTop: 20}} variant="contained"
									color="primary" type="submit" disabled={isLoading || (user === "" || password === "")}
									startIcon={isLoading && <CircularProgress size={"1.3rem"}/>} >
								Entrar
							</Button>

						</div>

					</form>

				</Paper>
			</div>
			<Snackbar open={snackErr} autoHideDuration={6000} onClose={() => setSnackErr(false)}>
				<Alert onClose={() => setSnackErr(false)} severity="error">
					{snackMsg}
				</Alert>
			</Snackbar>
		</>


	)
}

export default Login;
