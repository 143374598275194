import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {cleanRow} from "../../utils/utils";
import {Button, FormControlLabel, Snackbar, Switch} from "@material-ui/core";
import {eliminatoriesColumns} from "./eliminatoriesTableContent";
import {
	getTipusEliminatoriesService,
	updateTipusEliminatoriesService
} from "../../data/services/editarTemporada/tipusEliminatoriesService";
import Alert from "@material-ui/lab/Alert";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import {Prompt} from "react-router-dom";
import moment from "moment";

const emptyRow = {
	titol: "",
	tipus: "",
	nom: "",
	num_sardanes: 0,
	sardanes_visibles: {
		value: false,
		onChange: null
	},
	vots_blanc: {
		value: false,
		onChange: null
	},
	admetre_votacio: {
		value: false,
		onChange: null
	},
	mostrar_ordinal: {
		value: false,
		onChange: null
	},
	comentari: "",
};

const TipusEliminatoriesScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);
	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");


	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");

	const onChangeSwitchCell = (params, columnName) => {
		let newRows = [...rows]
		let rowIndex = newRows.findIndex(element => element.id === params.id);
		newRows[rowIndex][columnName].value = !newRows[rowIndex][columnName].value
		setRows(newRows)

	}

	useEffect(() => {
		setIsLoading(true)
		getTipusEliminatoriesService()
			.then(res => {
				setColumns(eliminatoriesColumns)
				setRows(res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[tipusEliminatoriesScreen] error fetching rows", err)
				setIsLoading(false)
			})
	}, [])

	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		//start loader
		if (!silent) setIsLoading(true)
		updateTipusEliminatoriesService(rows)
			.then(res => {
				if (refreshContent) {
					setRows(res.rows)
				}
				if (!silent) {
					setIsLoading(false)
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
				}
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[tipusEliminatoriesScreen] error send rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	};
	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_SEND_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[tipusEliminatoriesScreen] edited rows",editedRows)
		if (autoSave) saveTable(true, true, editedRows);
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>

			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={columns}
				editable={true}
				deletable={true}
				insertable={true}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				emptyRow={emptyRow}
				addNewRow={cleanRow}
				isLoading={isLoading}
			/>

			<div dangerouslySetInnerHTML={{__html:`
					<p><strong>Instruccions:</strong></p>
					<p><span style="font-weight: 400;">La taula es preomple de forma autom&agrave;tica amb diferents tipus d&rsquo;eliminat&ograve;ries. Editeu els tipus que necessiteu i esborreu els que no facin falta.</span></p>
					<p><span style="font-weight: 400;">Significat de les dades:</span></p>
					<ul>
					<li style="font-weight: 400;" aria-level="1"><strong>Tipus d&rsquo;eliminat&ograve;ria: </strong><span style="font-weight: 400;">Nom del tipus d&rsquo;eliminat&ograve;ria tal i com cal mostrar-lo al web. Ha d&rsquo;estar en plural quan hi hagi m&eacute;s d&rsquo;una eliminat&ograve;ria d&rsquo;aquest tipus. Exemples: Eliminat&ograve;ries, Repesca, Semifinals, Premi Joventut, Premi de la Cr&iacute;tica.</span></li>
					<li style="font-weight: 400;" aria-level="1"><strong>Eliminat&ograve;ria actual: </strong><span style="font-weight: 400;">Nom de l&rsquo;eliminat&ograve;ria actual, sense n&uacute;mero d&rsquo;ordre. Quan &eacute;s un tipus d&rsquo;eliminat&ograve;ria que es repeteix diverses setmanes, en aquesta columna s&rsquo;ha de posar el nom comen&ccedil;ant amb min&uacute;scules, pensant en que haur&agrave; d&rsquo;anar numerat i cal activar el camp &ldquo;Mostrar Ordinal&rdquo;. Per exemple: &ldquo;eliminat&ograve;ria&rdquo;.</span></li>
					<li><strong>Títol curt: </strong>per l'identificador de 4 lletres del tipus d&rsquo;eliminat&ograve;ria. S&rsquo;utilitza al nom dels &agrave;udios.</li>
					<li style="font-weight: 400;" aria-level="1"><strong>Num. sardanes: </strong><span style="font-weight: 400;">n&uacute;mero de sardanes que tindr&agrave; aquest tipus d&rsquo;eliminat&ograve;ria.</span></li>
					<li style="font-weight: 400;" aria-level="1"><strong>Sardanes visibles: </strong><span style="font-weight: 400;">activa o no que els votants puguin veure el t&iacute;tol de la sardana durant les votacions.</span></li>
					<li style="font-weight: 400;" aria-level="1"><strong>Permetre vots en blanc: </strong><span style="font-weight: 400;">activa o no la possibilitat que els votants puguin emetre vots en blanc.</span></li>
					<li style="font-weight: 400;" aria-level="1"><strong>Admetre votaci&oacute;: </strong><span style="font-weight: 400;">activa o no la possibilitat que els votants puguin votar.</span></li>
					<li style="font-weight: 400;" aria-level="1"><strong>Mostrar ordinal: </strong><span style="font-weight: 400;">activa o no la numeraci&oacute; de les successives eliminat&ograve;ries del mateix tipus.&nbsp; Per exemple, això fa que es mostri 1a Eliminatòria al web en comptes de 1 Eliminatòria.</span></li>
					<li><strong>Comentari: </strong><span style="font-weight: 400;">Aquest text substituir&agrave; el formulari de votacions aquelles setmanes en que les sardanes emeses siguin nom&eacute;s per donar-les a con&egrave;ixer, no per votar-les (Premi Joventut o de la Cr&iacute;tica, Rep&agrave;s de finalistes&hellip;)</span></li>
					</ul>
				`}} />
				<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</BodyNewSeasonScreen>
	)
};

export default TipusEliminatoriesScreen;
