import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from '../../assets/LSDA-scaled.jpg';
import {recoverPasswordFromEmailService, recoverPasswordSetNewPasswordService} from "../../data/services/authService";
import {CircularProgress, Snackbar} from "@material-ui/core";
import {useParams} from "react-router-dom";
import Alert from "../../components/unit/Alert/Alert";


const RecoverPassword = ({history}) => {


	const {token} = useParams();

	const [user, setUser] = useState("");
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");

	const [snackSuccess, setSnackSuccess] = useState(false);
	const [snackErr, setSnackErr] = useState(false);
	const [snackMsg, setSnackMsg] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const submitFormRecoverFromEmail = async () => {
		setIsLoading(true)
		recoverPasswordFromEmailService(user)
			.then(res => {
				setIsLoading(false)
				history.push("recuperar-contrasenya/enviat");
			})
			.catch(error => {
				setIsLoading(false)
				setSnackMsg("Error: " + error)
				setSnackErr(true);
			})

	}

	const submitFormSetNewPassword = async () => {
		if (password1 === "" || password2 === "") {
			setIsLoading(false)
			setSnackMsg("Les contrasenyes no coincideixen.")
			setSnackErr(true);
			return;
		}
		if (password1 !== password2 || password1.length < 8) {
			setIsLoading(false)
			setSnackMsg("Les contrasenyes no coincideixen. Recorda que ha de tenir 8 caràcters com a mínim");
			setSnackErr(true);
			return;
		}
		setIsLoading(true)
		recoverPasswordSetNewPasswordService(token, password1, password2)
			.then(res => {
				setSnackMsg("Canvi de contrasenya efectuat. En breus se't dirigirà a la pàgina d'inici de sessió.")
				setSnackSuccess(true)
				setTimeout(() => {
					history.push("/login");
				}, 2000)
			})
			.catch(error => {
				setIsLoading(false)
				setSnackMsg("Error canviant contrasenya: " + error)
				setSnackErr(true);
			})

	}

	const handleUserChange = (e) => {
		setUser(e.target.value)
	}

	const handlePasswordChange = (e, index) => {
		if (index === 1) {
			setPassword1(e.target.value)
		}
		else if (index === 2) {
			setPassword2(e.target.value)
		}
	}

	const renderForm = () => {
		return (
			<>
				<span style={{display: "flex", marginTop: 40}}>Indica el correu o usuari de registre</span>

				<form onSubmit={(event) => {
					event.preventDefault();
					submitFormRecoverFromEmail()
				}}>

					<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
						<TextField style={{display: "flex", marginTop: 30, width: 260}}
								   id="user" label="Usuari o correu electrònic" variant="outlined" onChange={handleUserChange}/>

						<a href="#" onClick={() => history.goBack()} style={{display: "flex", marginTop: 20}}>Enrere</a>

						<Button style={{display: "flex", marginTop: 20}} variant="contained"
								color="primary" type="submit" disabled={isLoading || user === ""}
								startIcon={isLoading && <CircularProgress size={"1.3rem"}/>} >
							Recuperar contrasenya
						</Button>

					</div>

				</form>
			</>
		)
	}

	const renderSentMail = () => {
		return (
			<>
				<span style={{display: "flex", marginTop: 40, textAlign: "center"}}>Comprova el teu correu. Si l'usuari o correu que has introduit és correcte, <br/>hauràs rebut un email amb un enllaç per recuperar la contrasenya.</span>
				<span style={{display: "flex", marginTop: 40}}>Si no reps el correu, contacta amb suport tècnic.</span>
				<span style={{display: "flex", marginTop: 40}}>Pots tancar aquesta pestanya si vols.</span>
			</>
		)
	}

	const renderNewPasswordForm = () => {
		return (<>
				<span style={{display: "flex", marginTop: 40, marginBottom: 20, textAlign: "center"}}>Introdueix una nova contrasenya. <br/> Ha de tenir com a mínim 8 caràcters.</span>

				<form onSubmit={(event) => {
					event.preventDefault();
					submitFormSetNewPassword()
				}}>

					<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
						<TextField style={{display: "flex", marginTop: 20, width: 260}} id="password"
								   label="Contrasenya"
								   variant="outlined" type="password" onChange={(e) => handlePasswordChange(e, 1)}/>

						<TextField style={{display: "flex", marginTop: 20, width: 260}} id="password"
								   label="Repeteix la contrasenya"
								   variant="outlined" type="password" onChange={(e) => handlePasswordChange(e, 2)}/>

						<Button style={{display: "flex", marginTop: 40}} variant="contained"
								color="primary" type="submit" disabled={isLoading || (password2 === "" || password1 === "")}
								startIcon={isLoading && <CircularProgress size={"1.3rem"}/>} >
							Canviar contrasenya
						</Button>

					</div>

				</form>
			</>
		)
	}

	return (
		<>

			<div style={{
				display: "flex",
				flex: 1,
				height: "100vh",
				width: "100vw",
				alignItems: "center",
				justifyContent: "center"
			}}>


				<Paper elevation={3}
					   style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 40}}>

					<img src={logo} style={{height: 46, width: 46, objectFit: "contain", marginTop: -23}}/>

					{!token && renderForm()}
					{(token && token === "enviat") && renderSentMail()}
					{(token && token !== "enviat") && renderNewPasswordForm()}

				</Paper>
			</div>
			<Snackbar open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}>
				<Alert onClose={() => setSnackSuccess(false)} severity="success">
					{snackMsg}
				</Alert>
			</Snackbar>

			<Snackbar open={snackErr} autoHideDuration={6000} onClose={() => setSnackErr(false)}>
				<Alert onClose={() => setSnackErr(false)} severity="error">
					{snackMsg}
				</Alert>
			</Snackbar>
		</>


	)
}

export default RecoverPassword;
